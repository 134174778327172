var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.data != null)?_c('div',{staticClass:"admin-container"},[_c('v-row',{staticClass:"justify-space-between"},[_c('v-col',{attrs:{"cols":"12","md":"3"}},[_c('h1',{staticClass:"col-secondary-1"},[_vm._v("Reklamace")])]),_c('v-spacer'),_c('v-col',{attrs:{"align-self":"center"}},[_c('v-row',[_c('v-col',{staticClass:"pa-0 flex-1 mt-8 mt-md-0 d-flex justify-end flex-wrap flex-md-nowrap",attrs:{"align-self":"center"}},[(['SUPERUSER', 'PROJECT_MANAGER'].includes(_vm.user.role))?_c('v-btn',{staticClass:"mb-4 mt-mb-0 mobile-width-send-message",attrs:{"x-small":"","tile":"","color":"secondary","height":"38"},on:{"click":function($event){return _vm.$router.push({
                name: 'Complaints-new',
              })}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"small":"","color":"primary"}},[_vm._v("mdi-plus")]),_vm._v(" Nová reklamace")],1):_vm._e()],1)],1)],1)],1),_c('v-row',{staticClass:"mt-10"},_vm._l((_vm.data),function(complaint,complaintIdx){return _c('v-col',{key:complaintIdx,attrs:{"cols":"12","md":"4"}},[_c('v-card',{staticClass:"pa-4 d-flex flex-column",staticStyle:{"height":"100%"}},[_c('v-row',{staticStyle:{"cursor":"pointer"},on:{"click":function($event){return _vm.$router.push({
              name: 'Complaints-detail',
              params: { id: complaint.id },
            })}}},[_c('v-col',[_c('div',{staticClass:"projecting-card-name"},[_vm._v(_vm._s(complaint.name))]),_c('div',{staticClass:"projecting-card-date mt-2"},[_vm._v(" "+_vm._s(complaint.date_of_record)+" ")]),_c('div',{staticClass:"projecting-card-editor mt-2"},[_vm._v(" Stav reklamace: "),_c('strong',[_vm._v(_vm._s(complaint.status))])])])],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"tile":"","color":"secondary","x-small":""},on:{"click":function($event){return _vm.$router.push({
                name: 'Complaints-detail',
                params: { id: complaint.id },
              })}}},[_vm._v("Detail")])],1)],1)],1)}),1)],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }