<template>
  <div class="admin-container" v-if="data != null">
    <v-row class="justify-space-between">
      <v-col cols="12" md="3">
        <h1 class="col-secondary-1">Reklamace</h1>
        <!-- <span class="col-secondary-2"
          >Zde naleznete záznamy ze stavebního deníku a ze stavby vašeho
          domu.</span
        > -->
      </v-col>
      <v-spacer></v-spacer>
      <v-col align-self="center">
        <v-row>
          <v-col
            align-self="center"
            class="pa-0 flex-1 mt-8 mt-md-0 d-flex justify-end flex-wrap flex-md-nowrap"
          >
            <!-- <v-btn
              x-small
              height="38"
              color="primary"
              tile
              class="mr-md-2 mobile-width-send-message mb-4 mb-md-0"
              @click="toMessages"
            >
              <div class="d-flex flex-align-center">
                <img
                  :src="require('@/assets/poslat_zpravu.svg')"
                  class="icon-wrapp mr-2"
                  style="width: 13px; height: 13px"
                />
                Poslat zprávu
              </div>
            </v-btn> -->

            <!-- <v-btn
              x-small
              height="38"
              class="mobile-width-send-message mr-md-2 mb-4 md-mb-0"
              tile
              @click="downloadAll"
              >Stáhnout vše</v-btn
            > -->
            <!-- v-if="user.role != 'END_USER' && user.role != 'COMPANY_CUSTOMER'" -->
            <v-btn
              v-if="['SUPERUSER', 'PROJECT_MANAGER'].includes(user.role)"
              x-small
              tile
              color="secondary"
              height="38"
              @click="
                $router.push({
                  name: 'Complaints-new',
                })
              "
              class="mb-4 mt-mb-0 mobile-width-send-message"
              ><v-icon small color="primary" class="mr-2">mdi-plus</v-icon> Nová
              reklamace</v-btn
            >
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row class="mt-10">
      <v-col
        cols="12"
        md="4"
        v-for="(complaint, complaintIdx) in data"
        :key="complaintIdx"
      >
        <v-card class="pa-4 d-flex flex-column" style="height: 100%">
          <v-row
            style="cursor: pointer"
            @click="
              $router.push({
                name: 'Complaints-detail',
                params: { id: complaint.id },
              })
            "
          >
            <v-col>
              <div class="projecting-card-name">{{ complaint.name }}</div>
              <div class="projecting-card-date mt-2">
                {{ complaint.date_of_record }}
              </div>
              <div class="projecting-card-editor mt-2">
                Stav reklamace: <strong>{{ complaint.status }}</strong>
              </div>
            </v-col>
          </v-row>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              tile
              color="secondary"
              x-small
              @click="
                $router.push({
                  name: 'Complaints-detail',
                  params: { id: complaint.id },
                })
              "
              >Detail</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import authHandler from "../api/authHandler";
import permissionDeniedHandler from "../api/permissionDeniedHandler";
import bus from "../plugins/bus";
// import documentService from "../api/documentService";
// import downloadService from "../api/downloadService";
import errorHandler from "../api/errorHandler";
import complaintService from "../api/complaintService";
export default {
  data() {
    return {
      data: null,
      focusedId: null,
      documents: [],
    };
  },
  async created() {
    try {
      if (this.getSelectedBuilding() != null) {
        await this.index();
        this.computeStyles();
      } else {
        this.$router.push({ name: "Dashboard" });
      }
    } catch (error) {
      if (!authHandler(error)) this.$router.push({ name: "Login" });
      permissionDeniedHandler(error, this.$router);
    }
  },
  methods: {
    computeStyles() {
      const wrapperTimeline = document.querySelector(
        ".v-timeline.mt-10.v-timeline--dense.theme--light"
      );
      const notPassed = document.querySelectorAll(
        ".v-timeline-item__inner-dot.grey"
      );
      const passed = document.querySelectorAll(
        ".v-timeline-item__inner-dot.primary"
      );
      const totalItems = notPassed.length + passed.length;
      if (totalItems) {
        const itemHeight =
          wrapperTimeline.getBoundingClientRect().height / totalItems;

        passed.forEach((el) => {
          el.style.setProperty("--height", itemHeight + "px");
        });
        notPassed.forEach((el) => {
          el.style.setProperty("--height", itemHeight + "px");
        });
      }
    },
    handleClick(ref, idx, id) {
      this.focusedId = id;
      this.$refs[ref][idx].click();
    },
    toMessages() {
      this.$router.push({
        name: "MessagesDetail",
        params: { id: this.getSelectedBuilding().project_manager.id },
        query: {
          section: "complaints",
        },
      });
    },
    getSelectedBuilding() {
      const building = JSON.parse(localStorage.getItem("building")) || null;
      return building;
    },
    async index() {
      try {
        bus.$emit("processing");
        const data = await complaintService.index();
        this.data = data;
        console.debug(data);
        bus.$emit("processing", false);
        await bus.$emit("refresh_sections");
      } catch (error) {
        bus.$emit("processing", false);
        errorHandler(error);
      }
    },
    async store() {
      try {
        bus.$emit("processing");
        await complaintService.store({
          ...this.data,
          documents: this.documents,
        });
        bus.$emit("processing", false);
        this.$router.push({ name: "Projecting" });
      } catch (error) {
        bus.$emit("processing", false);
        console.error(error);
        if (
          error.response &&
          error.response.data &&
          error.response.data.error.message
        ) {
          bus.$emit("snackbar", {
            text: error.response.data.error.message,
            color: "error",
          });
        }
      }
    },
  },
  computed: {
    user() {
      return this.$store.getters.getUser;
    },
  },
};
</script>

<style></style>
